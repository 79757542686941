/**
 * This directive allows you to use v-click-outside="method". It's called when the user clicks outside
 * the target element.
 */
export const OutsideClick = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = event => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        }
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    }
}
